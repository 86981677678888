import Vue from "vue";

import singleSpaVue from "single-spa-vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "vuetify/dist/vuetify.min.css";

import "./plugins/base";
import "./plugins/chartist";
import "./plugins/vee-validate";
import "./plugins/vue-world-map";

import i18n from "./i18n";
import Axios from "axios";
import "./sass/custom.scss";
import VueHtml2Canvas from "vue-html2canvas";
Vue.use(VueHtml2Canvas);

const IRRIGATION_MANAGER_STYLESHEET_ID = "im-styles";
let irrigationManagerStyles = undefined;

Vue.prototype.$http = Axios;
const token = localStorage.getItem("token");
if (token) {
  Vue.prototype.$http.defaults.headers.common.Authorization = token;
}

Vue.config.productionTip = false;

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      return h(App, {
        props: {
          // single-spa props are available on the "this" object. Forward them to your component as needed.
          // https://single-spa.js.org/docs/building-applications#lifecycle-props
          // if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
          /*
          name: this.name,
          mountParcel: this.mountParcel,
          singleSpa: this.singleSpa,
          */
        },
      });
    },
    router,
    store,
    vuetify,

    i18n,
  },
});

export const bootstrap = (props) =>
  vueLifecycles.bootstrap(props).then(() => {
    const iconFont = document.createElement("link");
    iconFont.rel = "stylesheet";
    iconFont.href =
      "https://cdn.jsdelivr.net/npm/@mdi/font@5.x/css/materialdesignicons.min.css";
    document.head.appendChild(iconFont);

    const maps = document.createElement("script");
    maps.async = true;
    maps.defer = true;
    maps.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBrHcSo5Z7W4xw2-Iyvmc8__gfXjK8uifw&v=3.XY";
    document.head.appendChild(maps);
  });

export const mount = (props) =>
  vueLifecycles.mount(props).then(() => {
    /**
     * Workaround to load and delete micro styles when the module is loaded.
     * Since they are using the vuetify module, the styles are searched for any string that contains the following string .v-
     */
    if (!irrigationManagerStyles) {
      irrigationManagerStyles = Array.prototype.filter.call(
        document.head.querySelectorAll("style"),
        (styleIrrigationManager) => {
          if (styleIrrigationManager.innerHTML.match(/\.v-/gm)) {
            styleIrrigationManager.id = IRRIGATION_MANAGER_STYLESHEET_ID;
            return styleIrrigationManager;
          }
        }
      );
    } else {
      irrigationManagerStyles.forEach((styleNode) =>
        document.head.appendChild(styleNode)
      );
    }
  });

export const unmount = (props) =>
  vueLifecycles.unmount(props).then(() => {
    document.head
      .querySelectorAll(`#${IRRIGATION_MANAGER_STYLESHEET_ID}`)
      .forEach((styleNode) => styleNode.remove());
  });
